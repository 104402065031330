import React, { Component } from "react";
import CurrencyFlag from "./CurrencyFlag";

class CurrencySearch extends Component {
    constructor(props) {
        super(props)
        this.showDropdown = false
		this.fromRef = React.createRef()
		this.toRef = React.createRef()
		this.state = { tempValue: '', rate: '', fromAmount: '', toAmount: '', changedCurrency: false, setAmount: false }
    }

	componentDidUpdate(prevProps){
		if (this.props.newToCurrency !== prevProps.newToCurrency){
			this.setState({fromAmount: '', toAmount: '', }, () => {
				this.props.getSum({fromAmount: '', toAmount: '', setAmount: true})
			})
		}
	}

    render() {

		const updateValue = (e) => {
			this.setState({tempValue: e.target.value})

			let amount = e.target.value.replace(/\D/g, '').replaceAll(' ', '')
			this.props.checkRate(amount)

			let inputArray = e.target.value.replace(/\D/g, '').split(' ')

			let amountResult = ''

			for (let i = 0; i < inputArray.length; i++) {
				amountResult += inputArray[i]
			}

			let matchingCurrency = this.props.newToCurrency?.id ? this.props.currencyRates.find(rate => rate.attributes.targetCurrencyCode === this.props.newToCurrency.id) : this.props.currencyRates.find(rate => rate.attributes.targetCurrencyCode === 'USD')
				if (e.target.id === 'from-input') {

						let newAmount = Math.ceil(amountResult / matchingCurrency?.attributes?.rate).toString()

						this.setState({fromAmount: amountResult, toAmount: newAmount }, () => {
							this.props.getSum({fromAmount: this.state.fromAmount, toAmount: this.state.toAmount, setAmount: true})
						})
					}
				else if (e.target.id === 'to-input') {
					let newAmount = Math.ceil(amountResult * matchingCurrency?.attributes?.rate).toString()

						this.setState({toAmount: amountResult, fromAmount: newAmount }, () => {
							this.props.getSum({fromAmount: this.state.fromAmount, toAmount: this.state.toAmount, setAmount: true})
						})
				}
		}

    return (
        <div
            style={{
				order: '2',
				letterSpacing: '0.7px',
				textAlign: 'right',
				position: 'relative',
				// borderRadius: '0 10px 10px 0',
				width: '140px',
				overflow: 'hidden',
				textOverflow: 'ellipsis'
			}}
            translate="no" className='hero-converter-block__search' ref={node => { this.node = node }}
			onFocus={() => this.props.campaignBasket ? this.props.scrollToElement() : null}>

            <div className='hero-converter-block__search-container focused' style={{height: '66px'}}>
                {(this.showDropdown) && (
                    <div className='description-container-two'>
                            <button
                            style={{height: '66px'}}
                            className={`convert-button ${this.props.switchCurrencyCallback ? 'js-convert-to' : 'not-clickable'}`}
                            type='button'
                            tabIndex='-1'
                            onClick={this.props.switchCurrencyCallback}>
                            <CurrencyFlag
                                primaryFlagCode={this.props.currency.flagCode}
                                secundaryFlagCode={this.props.currency.destination.flagCode}
                                smallFlag={false}
                            />

                        </button>

                    </div>
				)}

                    <div style={{height: '66px', backgroundColor: 'white', display:'flex', flexDirection: 'column', justifyContent: 'center', }}>
						{this.props.origin === 'from-currency' ? 
						<input 
							id='from-input'
							ref={this.fromRef}
							autoComplete="off" 
							type="text"
							inputMode="numeric"
							style={{
								fontFamily: 'futura-med', border: 'none', textAlign: 'right', fontSize: '14px', marginRight: '10px', resize: 'none', outline: 'none', position: 'relative', zIndex: '1'}}
							onChange={updateValue}
							value={!this.props.lastFromAmount || this.props.lastFromAmount === '0' ? '' : Number(this.props.lastFromAmount).toLocaleString().replaceAll(',', ' ') }
							placeholder={this.props.placeholder}
							maxLength={11}
							>
						</input>
						: 
						<input 
							id='to-input'
							ref={this.toRef}
							autoComplete="off"
							type="text"
							inputMode="numeric"
							style={{
								fontFamily: 'futura-med',
								border: 'none', textAlign: 'right', fontSize: '14px', marginRight: '10px', resize: 'none', outline: 'none'}}
								onChange={updateValue}
								value={!this.props.lastToAmount || this.props.lastToAmount === '0' ? '' : Number(this.props.lastToAmount).toLocaleString().replaceAll(',', ' ')  }
								placeholder={this.props.placeholder}
								maxLength={11}
							>
						</input>
					}
					</div>
            </div>
        </div>
    )
}
}

export default CurrencySearch