import React, { Component, useEffect } from "react";
import DropdownComponent from "./DropdownComponent";
import DropdownItem from "./DropdownItem";
import CurrencyFlag from "./CurrencyFlag";
import { BiChevronDown } from 'react-icons/bi'
import { ImCross } from 'react-icons/im'
import { FaInfoCircle } from 'react-icons/fa'

class CurrencyDescription extends Component {
    constructor(props) {
        super(props)
        this.wrapperRef = React.createRef()
        this.buttonRef = React.createRef()
        this.searchRef = React.createRef()
        this.stopDropdown = this.stopDropdown.bind(this)
        this.startDropdown = this.startDropdown.bind(this)
        this.chooseCurrency = this.chooseCurrency.bind(this)
        this.state = {fromCurrency: this.props.fromCurrency, toCurrency: this.props.toCurrency, countries: this.props.countries, popularCurrencies: this.props.popularCurrencies, highlight: false, dropdown: false, choseNewCurrency: false, updatedCountries: false, searchVal: ''}
    }

    componentDidMount() {
            document.addEventListener("click", this.stopDropdown)
        }

    componentWillUnmount() {
        document.removeEventListener("click", this.stopDropdown)
    }

    stopDropdown(e) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(e.target) && e.target.classList[0] !== 'convert-button') {
            this.searchRef.current.value = ''
            this.setState({dropdown: false, searchVal: '', highlight: false})
        }
    }

    startDropdown() {
        if (!this.state.dropdown) {
        this.setState({dropdown: true}, () => {
            this.searchRef.current.focus()
            document.getElementById('scroll-bar').scrollTo({ top: 0})
        })
        }
    }

    chooseCurrency(currency) {
        this.setState({dropdown: false, searchVal: '', toCurrency: currency, choseNewCurrency: true}, () => {
            this.props.newToCurrencyFunction(this.state.toCurrency)
            this.props.resetValues(this.state.toCurrency)
        })
    }


    render() {

        const closeDropdown = () => {
            this.setState({dropdown: false})
        }

        const getAllCountryNames = () => {
            if (!this.state.updatedCountries) {
                setTimeout(() => {
                    let updatedValue = this.props.currencies.map((currency, i) => {
                        let countryId = currency?.relationships?.countries?.data[0]?.id
                        let matchedCountry = this.props.countries.find(country => country.id === countryId)
                        let allCountries = currency.relationships.countries.data.map(country => {
                            let matchingCountryName = this.props.countries.find(countryname => countryname.id === country.id)
                            return { name: matchingCountryName.attributes.name, flagCode: matchingCountryName.attributes.flagCode}
                        })
                        currency.countryNames = allCountries
                        if (currency.countryName === undefined) {
                        currency.searchWords = currency.id + currency.attributes.name
                        }
                        else {
                            currency.searchWords = currency.id + currency.countryName + currency.attributes.name
                        }
                        if (matchedCountry){
                        currency.countryName = matchedCountry.attributes.name
                        }
                        if (currency.id === 'USD') {
                            currency.countryName = 'USA'
                        }
                        if (currency.id === 'EUR') {
                            currency.countryName = 'Euro'
                        }
                        if (currency.id === 'THB') {
                            currency.countryName = 'Thailand'
                        }
                        if (currency.id === 'INR') {
                            currency.countryName = 'Indien'
                        }

                        if (currency?.relationships?.countries?.data.length < 2) {
                            let countryId = currency?.relationships?.countries?.data[0]?.id
                            let matchedCountry = this.props.countries.find(country => country.id === countryId)
                            if (matchedCountry) {
                                currency.countryName = matchedCountry.attributes.name
                            }
                            else if (currency.id === 'USD') {
                                currency.countryName = 'USA'
                            }
                        }
                        return currency
                    })
                    let updatedPopularProducts =  !this.props.popularCurrencies ? [] : this.props.popularCurrencies.map((currency, i) => {
                            let countryId = currency.id
                            let matchedCurrency = this.props.currencies.find(currency => currency.id === countryId)
                                let matchedCountry = this.props.countries.find(country => country.id === matchedCurrency.relationships?.countries?.data[0]?.id)
                                if (matchedCountry) {
                                    currency.countryName = matchedCountry.attributes.name
                                }
                            if (currency.id === 'USD') {
                                currency.countryName = 'USA'
                            }
                            if (currency.id === 'EUR') {
                                currency.countryName = 'Euro'
                            }
                            if (currency.id === 'THB') {
                                currency.countryName = 'Thailand'
                            }
                            return {currency, attributes: matchedCurrency.attributes, id: matchedCurrency.id, relationships: matchedCurrency.relationships, countryName: currency.id === 'EUR' ? 'Euro' : currency.id === 'USD' ? 'USA' : currency.id === 'THB' ? 'Thailand' : matchedCountry.attributes.name }
                        })
                this.setState({currencies: updatedValue, popularCurrencies: updatedPopularProducts, updatedCountries: true})
            }, 500)
        }
        }

        getAllCountryNames()

        const updateSearchvalue = (e) => {
            this.setState({searchVal: e.target.value})
        }

        const isHighlighted = this.state.highlight ? 'grey' : '#18161F'
        const closeHighlighted = this.state.closeHighlight ? 'grey' : '#18161F'
        const isDropdown = this.state.dropdown === true ? 'block' : 'none'
        const hideDescription = this.state.dropdown === true ? 'none' : 'flex'

        const origin = this.state.choseNewCurrency ? this.state.toCurrency : this.props.fromCurrency ? this.props.fromCurrency : this.props.toCurrency

        const name = this.props.toCurrency?.addedCountry?.name ?? this.props.toCurrency?.attributes?.name
        const initFromName = this.props.market === 'DNK' ? 'Danmark' : this.props.market === 'NOR' ? 'Norge' : this.props.market === 'FIN' ? 'Finland' : 'Sverige'
        const marketCurrencyName = this.props.market === 'DNK' ? 'DKK' : this.props.market === 'NOR' ? 'NOK' : this.props.market === 'FIN' ? 'EUR' : 'SEK'

    	return (
            <>
            {this.props.origin &&  (
                <div className='description-container'
                    style={{
                    maxWidth: '280px',
                    width: "70%",
                    backgroundColor: '#fff',
                    height: '66px',
                    display: 'flex',
                    zIndex: '99'
                }}
                onClick={this.props.origin === 'to-currency' ? () => this.startDropdown : null}
                >
                    {/* <DropdownComponent ref={this.wrapperRef} /> */}
                        <div ref={this.wrapperRef} id="dropdown" style={{width: '280px', height: '66px', borderRadius: '10px', display: isDropdown}}>
                            <div style={{
                            // borderRadius: '10px',
                            border: '1px lightgrey',
                            boxShadow: '2px 2px 2px #888888'}}>
                                <div style={{display: 'flex', flexDirection: 'column', position: 'relative', height: '66px'}}>
                                <input
                                    ref={this.searchRef}
                                    autoComplete="off"
                                    id="search-input"
                                    onChange={updateSearchvalue}
                                    style={{
                                        height: '66px',
                                        outline: 'none',
                                        display: 'inline',
                                        // borderRadius: '10px',
                                        border: '1px lightgrey',
                                        marginLeft: '5px',
                                        width: '200px'
                                    }}
                                    placeholder="Sök land eller valuta">
                                </input>
                                    <span style={{display: 'flex', position: 'absolute', left: '236px', height: '66px', margin: 'auto'}}><span onClick={closeDropdown} onMouseOver={() => this.setState({closeHighlight: true})}
                            onMouseOut={() => this.setState({closeHighlight: false})} style={{cursor: 'pointer', color: closeHighlighted,  transition: '0.3s', margin: 'auto 0', fontSize: '12px'}}><ImCross /></span></span>
                                    </div>
                                <div id="scroll-bar" style={{maxHeight: '400px', overflowY: 'scroll', zIndex: '99', position: 'relative' }}>
                                {this.state.updatedCountries ?
                                this.state.searchVal.length > 0 ?
                                <>
                                <div style={{backgroundColor: 'white', color: '#7C7C7C', fontSize: '14px', borderTop: '1px solid lightgrey', paddingLeft: '6px', fontFamily: 'futura-med'}}>
                                    Sökresultat för {this.state.searchVal}
                                </div>
                                { this.state.currencies.filter(currency => currency?.attributes.currencyCode !== marketCurrencyName && currency?.attributes.currencyCode !== "ANG" && ( currency?.searchWords.toUpperCase().includes(this.searchRef.current.value.toUpperCase()) || currency.countryNames.filter(name => name.name.toUpperCase().includes(this.searchRef.current.value.toUpperCase())).length > 0 )).map((currency, i) => {
                                    const bgColor = i % 2 === 1 ? '#f9fafc' : '#d9dcdf'
                                    
                                    let nameOfCountry = currency.countryNames.filter(name => name.name.toUpperCase().includes(this.searchRef.current.value.toUpperCase()))
                                    currency.addedCountry = nameOfCountry[0]

                                    const currentFilteredArray = this.state.currencies.filter(currency => currency?.searchWords.toUpperCase().includes(this.searchRef.current.value.toUpperCase()) || currency.countryNames.filter(name => name.name.toUpperCase().includes(this.searchRef.current.value.toUpperCase())).length > 0 )

                                    let valueArray = currentFilteredArray.map(function(item){return item.addedCountry?.name});
                                    let isDuplicate = valueArray.find(function(item, index){
                                        return valueArray.indexOf(item) !== index
                                    })

                                    const primaryOrSecondary = currentFilteredArray.length > 1 && currency.countryNames.length === 1 && isDuplicate?.includes(currency.addedCountry?.name) ? 'Primär valuta' : currentFilteredArray.length > 1 && currency.countryNames.length > 1 && isDuplicate?.includes(currency.addedCountry?.name) ? 'Alternativ valuta' : ''

                                    return (
                                        <div onClick={() => {this.chooseCurrency(currency)}} style={{cursor: "pointer"}} key={i}>
                                            <div style={{height: '66px', backgroundColor: bgColor, display: 'flex', alignItems: 'center', padding: '0 6px 0 6px'}}>
                                                <CurrencyFlag
                                                    primaryFlagCode={currency.attributes.flagCode ? currency.attributes.flagCode : 'se'}
                                                    smallFlag={false}
                                                />
                                                <div style={{display: 'flex', flexDirection: 'column', margin: 'auto 0'}}>
                                                    <div>
                                                        <span style={{fontFamily: 'futura-med'}}>{currency.attributes.currencyCode}</span>
                                                        {' - '}
                                                        <span style={{fontFamily: 'muli-reg', fontWeight: 'lighter'}}>{currency.countryNames.map(name => name.name.includes(this.searchRef.current.value)) && this.state.searchVal.length > 2 && nameOfCountry[0] !== undefined ? currency.addedCountry.name : currency.countryName}</span>
                                                        {' - '}
                                                        <span style={{fontFamily: 'muli-reg'}}>{currency.attributes.name}</span>
                                                    </div>
                                                    {currentFilteredArray.length > 1 && isDuplicate?.includes(currency.addedCountry?.name) ? 
                                                    <div>
                                                        <span style={{fontFamily: 'futura-med', fontSize: '14px'}}><FaInfoCircle /></span>
                                                        <span style={{fontFamily: 'futura-med', fontSize: '14px', margin: 'auto 0 auto 10px'}}>{primaryOrSecondary}</span>
                                                    </div>
                                                    : null}
                                                </div>

                                            </div>
                                        </div>
                                    )
                                }) }
                                </>
                                :
                                <>
                                <div style={{backgroundColor: 'white', color: '#7C7C7C', fontSize: '14px', borderTop: '1px solid lightgrey', paddingLeft: '6px', fontFamily: 'futura-med'}}>
                                    Populära valutor
                                </div>
                                { this.state.popularCurrencies.map((currency, i) => {
                                    const bgColor = i % 2 === 1 ? '#f9fafc' : '#d9dcdf'
                                    return (
                                        <div onClick={() => {this.chooseCurrency(currency)}} key={i}>
                                            <div style={{cursor: 'pointer', height: '66px', backgroundColor: bgColor, display: 'flex', alignItems: 'center', padding: '0 6px 0 6px',}}>
                                            <CurrencyFlag
                                            //primaryFlagCode='se'
                                            primaryFlagCode={currency.attributes?.flagCode ? currency.attributes?.flagCode : 'se'}
                                            currency={currency.currency}
                                            // secondaryFlagCode={this.props.currency.destination.flagCode}
                                            smallFlag={false}
                                        />
                                            {/* <DropdownItem currency={currency} bgColor={bgColor} index={i} /> */}
                                            <div>
                                                        <span style={{fontFamily: 'futura-med'}}>{currency.attributes.currencyCode}</span>
                                                        {' - '}
                                                        <span style={{fontFamily: 'muli-reg', fontWeight: 'lighter'}}>{currency.countryName}</span>
                                                        {' - '}
                                                        <span style={{fontFamily: 'muli-reg'}}>{currency.attributes.name}</span>
                                                    </div>
                                                    </div>
                                        </div>
                                    )
                                }) }
                                </>
                                : null}
                                </div>
                            </div>

                        </div>

                        <button
                            ref={this.buttonRef}
                            id="dropdown-button"
                            style={{
                                // borderRadius: '10px 0 0 10px',
                                display: hideDescription,
                                position: 'relative',
                                maxWidth: '280px',
                                width: '100%',
                                border: '2px solid transparent',
                                cursor: 'pointer',
                                backgroundColor: '#fff',
                                alignItems: 'center',
                                zIndex: '99'
                            }}
                            onMouseOver={() => this.setState({highlight: true})}
                            onMouseOut={() => this.setState({highlight: false})}
                            className='convert-button'
                            type='button'
                            tabIndex='-1'
                            onClick={this.props.origin === 'to-currency' ? this.startDropdown : null}
                        >

                            <CurrencyFlag
                            className="convert-button"
                             primaryFlagCode={
                                this.props.origin === 'to-currency' && this.state.choseNewCurrency ? this.state.toCurrency?.attributes.flagCode : this.props.origin === 'to-currency' ? this.props.toCurrency?.attributes?.flagCode : this.props.fromCurrency?.attributes?.flagCode }
                             currency={this.props.fromCurrency ? this.props.fromCurrency : this.props.toCurrency }
                            secondaryFlagCode={this.state.toCurrency?.addedCountry !== undefined ? this.state.toCurrency?.addedCountry?.flagCode : null}
                            smallFlag={false}
                        />
                        <div className='convert-button hero-currency-additional-info' style={{display: 'flex', flexDirection: 'column', zIndex: '1'}}>
                        {this.state.choseNewCurrency ?
                            <div className='convert-button convert-text convert-text--currency' style={{display: 'flex', flexDirection: 'column'}}>
                                <div className="convert-button" style={{margin: 'auto', textAlign: 'left'}}>
                                {/* {this.state.toCurrency.attributes.currencyCode} */}
                                <div className="convert-button" style={{fontFamily: 'muli-reg'}} id='countryName'>{origin?.addedCountry === undefined ? origin?.countryName : origin?.addedCountry?.name}</div>
                                <div className="convert-button" style={{fontFamily: 'muli-reg'}}>{origin?.attributes.name} ({origin?.id})</div>
                                </div>
                            </div>
                            :
                            <div className='convert-button convert-text convert-text--currency' style={{display: 'flex', flexDirection: 'column'}}>
                                <div style={{margin: 'auto', textAlign: 'left'}}>
                                {/* {this.currency.currencyCode} */}
                                <div style={{fontFamily: 'muli-reg', textTransform: 'capitalize'}} id='countryName' className="convert-button">
                                    {this.props.origin === 'from-currency' ? initFromName : name}
                                </div>

                                <div className="convert-button" style={{fontFamily: 'muli-reg'}}>{this.props.currency?.id === 'EUR' ? '' : origin?.attributes?.name} ({origin?.attributes?.currencyCode})</div>
                                </div>
                            </div>
                        }
                    </div>
                    {this.props.origin === 'to-currency' ? <span className="convert-button" style={{display: 'flex', position: 'absolute', right: "10px", height: '66px'}}><span className="convert-button" style={{color: isHighlighted, transition: '0.3s', margin: 'auto 0', fontSize: '28px'}}><BiChevronDown className="convert-button" /></span></span>: null}
                </button>


            </div>
            )}
            </>
        )
}
}

export default CurrencyDescription