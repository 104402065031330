import React, { Component, useEffect } from "react";
import CurrencyInput from "./CurrencyInput";
import { BiChevronRight } from 'react-icons/bi'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import { AiFillWarning } from 'react-icons/ai'
import ClipLoader from 'react-spinners/ClipLoader'
import ForexIcon from '../images/forexicon.png'
import MastercardIcon from '../images/mastercard.svg'
import VisaIcon from '../images/visa.png'
import SwishIcon from '../images/swish.svg'
import ZimplerIcon from '../images/zimpler.png'
import '../lib/styles/main.css'


class CurrencyConverter extends Component {
    constructor(props) {
        super(props)
        this.state = {vertMode: false, rateSum: '', calcSum: '', timesRate: '', loadedRate: false, loadedSum: false, changed: false, loading: false, newToCurrency: this.props.toCurrency }
    }

    componentDidMount() {
        this.setState({newToCurrency: this.props.toCurrency})
    }

    componentDidUpdate() {
        if (this.state.loadedRate && this.state.loadedSum && !this.state.changed && this.state.lastToAmount) {
                    let matchingCurrency = this.props.currencyRates.find(rate => rate.attributes.targetCurrencyCode === this.props.toCurrency.id)
                    this.setState({calcSum: this.roundUp(this.state.lastToAmount), matchingCurrencyId: matchingCurrency.id, timesRate: matchingCurrency.attributes.rate, loadedRate: false, loadedSum: false, loading: true}, () => {
                        const payingAmountCalc = this.state.calcSum * this.state.timesRate
                        let stringSplit = payingAmountCalc.toFixed(2).toString().split(".")
                        let fullNumber = parseInt(stringSplit[0]).toLocaleString("en").replaceAll(',', '')
                        this.setState({payingAmount: fullNumber})
                        
                        setTimeout(() => {
                            this.setState({loading: false})
                        }, 500);
                    })
        }
        if (this.state.loadedRate && this.state.loadedSum && this.state.changed && this.state.lastToAmount) {
                    let matchingCurrency = this.props.currencyRates.find(rate => rate.attributes.targetCurrencyCode === this.state.newToCurrency?.id)
                    this.setState({calcSum: this.roundUp(this.state.lastToAmount), matchingCurrencyId: matchingCurrency.id, timesRate: matchingCurrency?.attributes.rate, loadedRate: false, loadedSum: false, loading: true}, () => {
                        const payingAmountCalc = this.state.calcSum * this.state.timesRate
                        let stringSplit = payingAmountCalc.toFixed(2).toString().split(".")
                        let fullNumber = parseInt(stringSplit[0]).toLocaleString("en").replaceAll(',', '')
                        this.setState({payingAmount: fullNumber})

                        setTimeout(() => {
                            this.setState({loading: false})
                        }, 500);
                    })
        } 
    }

    roundUp(number) {
        if (this.state.newToCurrency === undefined) {
            if (number % 10 === 0 && number !== 0) {
                return number
            }
            else return Math.ceil(number / 10) * 10
        }
        let smallestDenomination = Math.min(...this.state.newToCurrency?.attributes?.denominations)

        if (number % smallestDenomination === 0 && number !== 0) {
            return number
        }
        else {
            return Math.ceil(number / smallestDenomination) * smallestDenomination
        }
    }

    render() {
        const checkRate = (data) => {
            this.setState({rateSum: data, loadedRate: true})
        }

        const getSum = (sum) => {
            this.setState({ lastFromAmount: sum.fromAmount, lastToAmount: sum.toAmount, loadedSum: true})
        }

        const getAllData = () => {
            let matchingCurrency = this.props.currencyRates.find(rate => rate.attributes.targetCurrencyCode === this.state.newToCurrency?.id)
                    this.setState({calcSum: this.roundUp(this.state.lastToAmount), timesRate: matchingCurrency?.attributes?.rate, loadedRate: false, loadedSum: false, loading: true}, () => {
                        setTimeout(() => {
                            this.setState({loading: false})
                        }, 500);
                    })
        }

        const goToForexCashout = () => {
            let origin = this.state.newToCurrency !== undefined ? this.state.newToCurrency : this.props.toCurrency
            let smallDenomination = Math.min(...origin?.attributes?.denominations)
            let destinationUrl = this.props.market === 'DNK' ? 'https://www.forexvaluta.dk/indkobskurv' : this.props.market === 'NOR' ? 'https://www.forex.no/handlekurv' : this.props.market === 'FIN' ? 'https://www.forex.fi/ostoskori' : 'https://www.forex.se/varukorg'
            let countryCode = this.props.market === 'DNK' ? origin?.attributes?.availableForOnlinePurchase?.dnk : this.props.market === 'NOR' ? origin?.attributes?.availableForOnlinePurchase?.nor : this.props.market === 'FIN' ? origin?.attributes?.availableForOnlinePurchase?.fin : origin?.attributes?.availableForOnlinePurchase?.swe
            let maxAmount = this.props.market === 'FIN' ? 5000 : 50000
            if (Number.parseInt(fullNumber.replaceAll(' ', '')) < maxAmount && this.state.calcSum >= smallDenomination && countryCode) {
                // const testUrl = 'http://forex.externalweb.se.local/varukorg?currencyRate=' + this.state.matchingCurrencyId + '&amount=' + this.state.payingAmount.replace('%C2%A0', '') + '&companyId='
                const redirectUrl = destinationUrl + '?currencyRate=' + this.state.matchingCurrencyId + '&amount=' + this.state.payingAmount + '&partner=' + this.props.partner
                window.open(redirectUrl, '_blank')
            }
        }

        const newToCurrencyFunction = (data) => {
            this.setState({newToCurrency: data, lastToAmount: '', lastFromAmount: '', changed: true}, () => {
                getAllData()
            })
        }

        const clickInfo = () => {
            if (this.state.showInfo) {
                this.setState({showInfo: false})
            }
            else {
                this.setState({showInfo: true})
            }
        }

        const clickWarning = () => {
            if (this.state.showWarning) {
                this.setState({showWarning: false})
            }
            else {
                this.setState({showWarning: true})
            }
        }

        const hoverColor = ( this.state.hover && Number.parseInt(this.state.lastFromAmount) < 50001 ) && this.state.newToCurrency?.attributes.availableForOnlinePurchase.swe !== false ? '#e0dada' : 'white'
        const buttonShadow = ( this.state.hover && Number.parseInt(this.state.lastFromAmount) < 50001 ) && this.state.newToCurrency?.attributes.availableForOnlinePurchase.swe !== false ? '0px 2px 5px #888888' : ''

        const payingAmountCalc = this.state.calcSum * this.state.timesRate
        let stringSplit = payingAmountCalc.toFixed(2).toString().split(".")

        let fullNumber = parseInt(stringSplit[0]).toLocaleString("en").replaceAll(',', ' ')
        // this.setState({payingAmount: fullNumber})
        let decimals = stringSplit[1]

        let currencyId = this.state.changed ? this.state.newToCurrency?.id : this.props.toCurrency?.id

        let origin = this.state.newToCurrency !== undefined ? this.state.newToCurrency : this.props.toCurrency
        let countryCode = this.props.market === 'DNK' ? origin?.attributes?.availableForOnlinePurchase?.dnk : this.props.market === 'NOR' ? origin?.attributes?.availableForOnlinePurchase?.nor : this.props.market === 'FIN' ? origin?.attributes?.availableForOnlinePurchase?.fin : origin?.attributes?.availableForOnlinePurchase?.swe
        const maxAmount = this.props.market === 'FIN' ? 5000 : 50000
        const buttonColor = Number.parseInt(fullNumber.replaceAll(' ', '')) > maxAmount || !this.state.lastFromAmount || countryCode === false ? '#18161f33' : '#18161F'
        const cursor =  Number.parseInt(this.state.lastFromAmount) > maxAmount || !this.state.lastFromAmount || countryCode === false ?  'not-allowed' : 'pointer'

        const rateMultiplier = Number.parseFloat(this.state.timesRate) < 0.001 ? 1000 : Number.parseFloat(this.state.timesRate) < 0.01 ? 100 : Number.parseFloat(this.state.timesRate) < 0.1 ? 10 : 1

        //Content texts
        const resource_title = this.props.market === 'DNK' ? 'BYT VALUTA INDEN DU REJSER' : this.props.market === 'NOR' ? 'BYTT VALUTA FØR DU REISER' : this.props.market === 'FIN' ? 'VAIHDA VALUUTTA ENNEN MATKAA' : 'VÄXLA VALUTA INNAN DU RESER'
        const resource_paragraph = ''
        const resource_from = this.props.market === 'DNK' ? 'Fra' : this.props.market === 'NOR' ? 'Fra' : this.props.market === 'FIN' ? 'Alkaen' : 'Från'
        const resource_to = this.props.market === 'DNK' ? 'Til' : this.props.market === 'NOR' ? 'Til' : this.props.market === 'FIN' ? 'To' : 'Till'
        const resource_placeholder = this.props.market === 'DNK' ? 'Indtast beløb' : this.props.market === 'NOR' ? 'Angi beløp' : this.props.market === 'FIN' ? 'Anna määrä' : 'Ange belopp'
        const resource_summary = this.props.market === 'DNK' ? 'Opsummering' : this.props.market === 'NOR' ? 'Oppsummering' : this.props.market === 'FIN' ? 'Summaus' : 'Summering'
        const resource_rate = this.props.market === 'DNK' ? 'Valutakurs' : this.props.market === 'NOR' ? 'Vekslingskurs' : this.props.market === 'FIN' ? 'Valuuttakurssi' : 'Växlingskurs'
        const resource_receive = this.props.market === 'DNK' ? 'Jeg får' : this.props.market === 'NOR' ? 'Jeg får' : this.props.market === 'FIN' ? 'Saan' : 'Jag får'
        const resource_payAmount = this.props.market === 'DNK' ? 'Jeg betaler' : this.props.market === 'NOR' ? 'Jeg betaler' : this.props.market === 'FIN' ? 'Minä maksan' : 'Jag betalar'
        const resource_buttonText = this.props.market === 'DNK' ? 'Bestil valuta' : this.props.market === 'NOR' ? 'Bestil valuta' : this.props.market === 'FIN' ? 'Osta tai varaa valuuttaa' : 'Beställ valuta'
        const resource_updated = this.props.market === 'DNK' ? 'Opdateret' : this.props.market === 'NOR' ? 'Oppdatert' : this.props.market === 'FIN' ? 'Päivitetty' : 'Uppdaterad'

    return (
        <div>
            {!this.props.vertMode ?
            <div className='currency-converter__converter-container' style={{position: 'relative', zIndex: '100'}} >
                {this.state.showInfo ?
                    <div style={{position: 'absolute', zIndex: '101', left: '50%', top: '50%', marginLeft: '-120px', width: '240px', padding: '10px', fontFamily: 'muli-reg', backgroundColor: 'white', border: '2px solid #18161F' }}>{`Vi kan endast ge ut jämna belopp i ${currencyId}, därför har vi avrundat ditt belopp till närmsta möjliga.`}</div>
                : null}
                {this.state.showWarning ?
                    <div style={{position: 'absolute', zIndex: '101', left: '50%', top: '50%', marginLeft: '-120px', width: '240px', fontFamily: 'muli-reg', padding: '10px', backgroundColor: 'white', border: '2px solid #18161F'}}>Du kan växla valuta upp till ett maximalt värde av 50 000 SEK.</div>
                : null}
                <div className="forex-logo">
                <img width="200" src={ForexIcon} alt="Forex"></img>
                </div>
				<h2 className='hero-converter-block__currency-title' style={{textAlign: 'center', marginBottom: '0px', marginTop: '0px', color: '#18161F', fontFamily: 'futuracon-exbol', fontSize: '26px'}}>{resource_title}</h2>
                <p className="currency-preamble" style={{fontFamily: 'futura-med', fontSize: '14px', maxWidth: '806px', margin: '20px auto', textAlign: 'center'}}>Beställ valuta snabbt och enkelt och hämta i valfri FOREX Butik eller hos närmsta postombud. Betala direkt eller i butik. Fri frakt från 5 000 kr, säker kortbetalning och spårbar leverans.</p>
                {/* <p className="currency-preamble" style={{fontFamily: 'futura-med', fontSize: '14px', maxWidth: '806px', margin: '20px auto', textAlign: 'center'}}>Beställ din valuta snabbt och enkelt innan du reser - få mer tid över till semester!</p> */}
                <form className='converter-form' style={{ display: 'flex', maxWidth: '806px', margin: 'auto'}}>
                    <div className='vert-currencies'>
                        <div style={{
                                position: 'relative', 
                                margin: 'auto', 
                                maxWidth: '400px', 
                                display: 'flex',
                                flexFlow: 'row', 
                                justifyContent:'center',
                                alignItems: 'center',
                                fontFamily: 'futura-med'
                            }}>
                            <span>{this.props.marketAsCurrencyCode}</span>
                            <span style={{fontSize:'30px', margin: '10px 10px 5px 10px'}}>
                                <BiChevronRight />
                            </span>
                            <span>{currencyId}</span>
                        </div>
                    </div>
                    <div className='hero-converter-block__converter-box from-converter-box' style={{maxWidth: '400px', display: 'flex', flexDirection: 'column'}}>
                        <div className='title-input-container'>
                            <h3 className='converter-box-title' style={{marginLeft: '5px', marginBottom: '2px', color: '#18161F', fontFamily: 'futura-bol'}}>{resource_from}</h3>
                            <CurrencyInput placeholder={resource_placeholder} changedCurrency={this.state.changed} newToCurrency={this.state.newToCurrency} lastFromAmount={this.state.lastFromAmount} getSum={getSum} market={this.props.market} currencies={this.props.currencies} currencyRates={this.props.currencyRates} popularCurrencies={this.props.popularCurrencies} countries={this.props.countries} fromCurrency={this.props.fromCurrency} origin='from-currency' checkRate={checkRate} rateSum={this.state.rateSum}/>
                        </div>
                    </div>
                    <div className='hero-converter-block__converter-box to-converter-box' style={{maxWidth: '400px', display: 'flex', flexDirection: 'column'}}>
                        <div className='title-input-container'>
                            <h3 className='converter-box-title' style={{marginLeft: '5px', marginBottom: '2px', color: '#18161F', fontFamily: 'futura-bol'}}>{resource_to}</h3>
                            <CurrencyInput placeholder={resource_placeholder} changedCurrency={this.state.changed} newToCurrencyFunction={newToCurrencyFunction} newToCurrency={this.state.newToCurrency} market={this.props.market} lastToAmount={this.state.lastToAmount} getSum={getSum} currencies={this.props.currencies} currencyRates={this.props.currencyRates} popularCurrencies={this.props.popularCurrencies} countries={this.props.countries} toCurrency={this.props.toCurrency} origin='to-currency' checkRate={checkRate} rateSum={this.state.rateSum} />
                        </div>
                    </div>
                </form>
                {this.props.initialRate !== undefined ?
                <div>
                    <div className="hero-converter-summary">
                        <div className="hero-converter-summary-section">
                            <div style={{ marginBottom: '10px'}}>
                                <span style={{fontSize: '20px', fontWeight: 'bold', margin: '0 auto', fontFamily: 'futura-bol'}}>
                                {resource_summary}
                                </span>
                            </div>
                            <div style={{margin: '0 auto', display: 'flex' }}>
                                <div style={{width: '50%'}}>
                                    <p style={{marginTop: '0px', marginBottom: '20px', fontFamily: 'muli-reg'}}>{resource_rate}</p>
                                </div>
                                <div style={{width: '50%'}}>
                                <span style={{display: 'inline-block', float: 'right', fontFamily: 'muli-reg'}}>{rateMultiplier} {currencyId} = {this.state.timesRate !== '' ? Number.parseFloat(this.state.timesRate * rateMultiplier).toFixed(4) : this.props.initialRate.toFixed(4) } {this.props.fromCurrency?.id}</span>
                                </div>
                            </div>
                            <div style={{width: '100%', height: '2px', backgroundColor: 'lightgrey', marginTop: '10px', marginBottom: '0px'}}></div>
                            <div style={{margin: '0 auto', display: 'flex' }}>
                                <div style={{width: '50%'}}>
                                    <p style={{marginTop: '5px', marginBottom: '5px', fontFamily: 'muli-reg'}}>{resource_receive}</p>
                                </div>
                                <div style={{width: '50%'}}>
                                {this.state.loading ? 
                                <span style={{marginTop: '5px', display: 'inline-block', float: 'right', fontWeight: 'bold'}}>
                                <ClipLoader loading={this.state.loading} size={16} />
                                </span>
                                : 
                                <span style={{ marginTop: '5px', display: 'inline-block', float: 'right', fontFamily: 'muli-reg'}}>{this.state.calcSum === '' ? '0' : this.state.calcSum.toLocaleString("en").replaceAll(',', ' ')} {currencyId}
                                        <span style={{ position: 'relative'}}>
                                        <span style={{marginLeft: '10px', height: '20px', position: 'relative'}} 
                                        onMouseOver={() => {this.setState({showInfo: true})}}
                                        onMouseOut={() => {this.setState({showInfo: false})}}
                                        onClick={() => clickInfo()}>
                                            <IoMdInformationCircleOutline />
                                        </span>
                                    </span>
                                    </span>}
                                </div>
                            </div>
                            <div style={{margin: '0 auto', display: 'flex' }}>
                                <div style={{width: '50%'}}>
                                    <p style={{marginTop: '5px', marginBottom: '5px', fontFamily: 'futura-med'}}>{resource_payAmount}</p>
                                </div>
                                <div style={{width: '50%'}}>
                                {this.state.loading ? 
                                <span style={{marginTop: '5px', display: 'inline-block', float: 'right', fontWeight: 'bold'}}>
                                <ClipLoader loading={this.state.loading} size={16} />
                                </span>
                                : 
                                <span style={{marginTop: '5px', display: 'inline-block', float: 'right', fontFamily: 'futura-med'}}>{fullNumber}.{decimals} {this.props.fromCurrency?.id}
                                {Number.parseInt(fullNumber.replaceAll(' ', '')) > maxAmount ? 
                                <span 
                                    onMouseOver={() => {this.setState({showWarning: true})}}
                                    onMouseOut={() => {this.setState({showWarning: false})}}
                                    onClick={() => clickWarning()}
                                    style={{marginLeft: '10px', height: this.state.hover ? '30px' : '20px', marginTop: '2px', position: 'relative'}}>
                                            <AiFillWarning />
                                </span>
                                : null}
                                </span>
                                }
                                </div>
                            </div>
                        </div>
                        <div className="hero-converter-summary-section align-items-center flex-direction-column">
                            <div className="hero-converter-summary-order">
                                {this.state.newToCurrency?.attributes.availableForOnlinePurchase.swe === false ?
                                <div style={{ marginBottom: '26px'}}>
                                    <span style={{fontFamily: 'futura-med', position: 'relative', top: '6px'}}><AiFillWarning /> Forex handlar i nuläget inte med {this.state.newToCurrency.id}</span>
                                </div>
                                :
                                <div style={{width: '210px', margin: '0 auto'}} onClick={() => goToForexCashout()}>
                                <button onMouseOver={() => {this.setState({hover: true})}}
                                    onMouseOut={() => {this.setState({hover: false})}}
                                    disabled={ this.state.newToCurrency?.attributes.availableForOnlinePurchase === false}
                                    style={{ cursor: cursor, padding: '5px', color: hoverColor, transition: '0.3s', border: 'black', backgroundColor: buttonColor, borderRadius: '30px', width: '210px', margin: '0 auto', boxShadow: buttonShadow }}>
                                        <p style={{margin: '5px auto', textAlign: 'center', fontFamily: 'futura-bol', letterSpacing: '1px'}}>{resource_buttonText}</p>
                                </button>
                                </div>
                                }
                                {this.props.market === 'SWE' || this.props.market === 'FIN' ? 
                                <div style={{width: '130px', margin: '10px auto 0 auto', height: '20px'}}>
                                    {this.props.market === 'SWE' ? 
                                        <img width="30" src={SwishIcon} alt="swish"></img>
                                    : this.props.market === 'FIN' ?
                                        <img width="30" src={ZimplerIcon} alt="swish"></img>
                                    : null}
                                    <img  style={{marginLeft: '10px', marginRight: '10px', paddingBottom: '3px'}} width="50"  src={VisaIcon} alt="visa"></img>
                                    <img width="30"  src={MastercardIcon} alt="masterCard"></img>
                                </div>
                                : null}
                            </div> 
                        </div>
                    </div>
                </div>
                : null} 
                {this.props.lastUpdated && 
                <div style={{fontSize: '12px', textAlign: 'center', color: '#18161F', fontFamily: 'muli-reg'}}>
                    <span>
                        {resource_updated} {this.props.lastUpdated.date} {this.props.lastUpdated.hour.slice(0, 5)}
                    </span>
                </div>
                }
            </div>

            :

            // VERT MODE

            <div className='currency-converter__converter-container' style={{position: 'relative'}}>
                {this.state.showInfo ?
                    <div style={{position: 'absolute', zIndex: '101', left: '50%', top: '50%', marginLeft: '-120px', width: '240px', padding: '10px', fontFamily: 'muli-reg', backgroundColor: 'white', border: '2px solid #18161F' }}>{`Vi kan endast ge ut jämna belopp i ${currencyId}, därför har vi avrundat ditt belopp till närmsta möjliga.`}</div>
                : null}
                {this.state.showWarning ?
                    <div style={{position: 'absolute', zIndex: '101', left: '50%', top: '50%', marginLeft: '-120px', width: '240px', padding: '10px', fontFamily: 'muli-reg', backgroundColor: 'white', border: '2px solid #18161F'}}>Du kan växla valuta upp till ett maximalt värde av 50 000 SEK.</div>
                : null}
                <div style={{width: '200px',  margin: '0 auto 20px auto',paddingTop: '20px'}}>
                    <img width="200" src={ForexIcon} alt="Forex"></img>
                </div>
				<h2 className='hero-converter-block__currency-title' style={{textAlign: 'center', marginBottom: '0px', marginTop: '0px', color: '#18161F', fontFamily: 'futuracon-exbol', fontWeight: '800', fontSize: '26px'}}>{resource_title}</h2>
                <p style={{fontFamily: 'futura-med', fontSize: '16px', maxWidth: '400px', margin: '20px auto 10px auto', textAlign: 'center'}}>Beställ valuta snabbt och enkelt och hämta i valfri FOREX Butik eller hos närmsta postombud. Betala direkt eller i butik. Fri frakt från 5 000 kr, säker kortbetalning och spårbar leverans.</p>
                <div style={{
                        position: 'relative', 
                        margin: 'auto', 
                        maxWidth: '400px', 
                        display:'flex', 
                        flexFlow: 'row', 
                        justifyContent:'center',
                        alignItems: 'center',
                        fontFamily: 'futura-med'
                    }}>
                    <span>{this.props.marketAsCurrencyCode}</span>
                    <span style={{fontSize:'30px', margin: '10px 10px 5px 10px'}}>
                        <BiChevronRight />
                    </span>
                    <span>{currencyId}</span>
                </div>
                <form className={`hero-converter-block__converter-form`} style={{ display: 'flex', flexDirection: 'column', margin: 'auto'}}>
                    <div className='hero-converter-block__converter-box' style={{maxWidth: '400px', display: 'flex', flexDirection: 'column', margin: '0 auto'}}>
                        <CurrencyInput placeholder={resource_placeholder} changedCurrency={this.state.changed} newToCurrency={this.state.newToCurrency} lastFromAmount={this.state.lastFromAmount} market={this.props.market} getSum={getSum} currencies={this.props.currencies} currencyRates={this.props.currencyRates} popularCurrencies={this.props.popularCurrencies} countries={this.props.countries} fromCurrency={this.props.fromCurrency} origin='from-currency' checkRate={checkRate} rateSum={this.state.rateSum}/>
                    </div>
                    <div className='hero-converter-block__converter-box' style={{maxWidth: '400px', display: 'flex', flexDirection: 'column', margin: '0 auto'}}>
                        <CurrencyInput placeholder={resource_placeholder} changedCurrency={this.state.changed} newToCurrencyFunction={newToCurrencyFunction} newToCurrency={this.state.newToCurrency} market={this.props.market}  lastToAmount={this.state.lastToAmount} getSum={getSum} currencies={this.props.currencies} currencyRates={this.props.currencyRates} popularCurrencies={this.props.popularCurrencies} countries={this.props.countries} toCurrency={this.props.toCurrency} origin='to-currency' checkRate={checkRate} rateSum={this.state.rateSum} />
                    </div>
                </form>
                {this.state.newToCurrency?.attributes.availableForOnlinePurchase.swe === false ?
                <div style={{width: '400px', backgroundColor: 'white', padding: '20px', margin: '9px auto 15px auto', boxShadow: '3px 5px 10px lightgrey'}}>
                    <p style={{margin: '0 auto', fontWeight: 'bold', fontSize: '40px', textAlign: 'center'}}><IoMdInformationCircleOutline /></p>
                    <p style={{marginTop: '5px', marginBottom: '5px'}}>Forex handlar för närvarande <span style={{fontWeight: 'bold'}}>inte</span> med <span style={{fontWeight: 'bold'}}>{currencyId}</span>. Du kan fortfarande se dess valutakurs, eller växla till någon av våra andra valutor. </p>
                </div> 
                : null}
                {this.props.initialRate !== undefined ?
                <div>
                    <div style={{ maxWidth: '360px', display: 'flex', flexDirection: 'column', height: 'auto', margin: '0 auto', backgroundColor: '#f5f5f5', padding: '20px', boxShadow: '10px', marginBottom: '20px'}}>
                        <div style={{width: '100%', paddingRight: '3px'}}>
                            <div style={{ marginBottom: '10px'}}>
                                <span style={{fontSize: '20px', fontWeight: 'bold', margin: '0 auto', fontFamily: 'futura-bol'}}>
                                {resource_summary}
                                </span>
                            </div>
                            <div style={{margin: '0 auto', display: 'flex' }}>
                                <div style={{width: '50%'}}>
                                    <p style={{marginTop: '0px', marginBottom: '20px', fontFamily: 'muli-reg'}}>{resource_rate}</p>
                                </div>
                                <div style={{width: '50%'}}>
                                <span style={{marginTop: '5px', display: 'inline-block', float: 'right', fontFamily: 'muli-reg'}}>1 {currencyId} = {this.state.timesRate !== '' ? this.state.timesRate.toFixed(4) : this.props.initialRate.toFixed(4) } {this.props.fromCurrency?.id}</span>
                                </div>
                            </div>
                            <div style={{width: '100%', height: '2px', backgroundColor: 'lightgrey', marginTop: '10px', marginBottom: '0px'}}></div>
                            <div style={{margin: '0 auto', display: 'flex' }}>
                                <div style={{width: '40%'}}>
                                    <p style={{marginTop: '5px', marginBottom: '5px', fontFamily: 'muli-reg'}}>{resource_receive}</p>
                                </div>
                                <div style={{width: '60%'}}>
                                {this.state.loading ? 
                                <span style={{marginTop: '5px', display: 'inline-block', float: 'right', fontWeight: 'bold'}}>
                                <ClipLoader loading={this.state.loading} size={16} />
                                </span>
                                : 
                                <span style={{marginTop: '5px', display: 'inline-block', float: 'right', fontFamily: 'muli-reg'}}>{this.state.calcSum === '' ? '0' : this.state.calcSum.toLocaleString("en").replaceAll(',', ' ')} {currencyId}
                                        <span style={{marginLeft: '10px', height: '20px', position: 'relative'}} 
                                        onMouseOver={() => {this.setState({showInfo: true})}}
                                        onMouseOut={() => {this.setState({showInfo: false})}}
                                        onClick={() => clickInfo()}>
                                            <IoMdInformationCircleOutline />
                                        </span>
                                    </span>}
                                </div>
                            </div>
                            <div style={{margin: '0 auto', display: 'flex' }}>
                                <div style={{width: '40%'}}>
                                    <p style={{marginTop: '5px', marginBottom: '5px', fontFamily: 'futura-med'}}>{resource_payAmount}</p>
                                </div>
                                <div style={{width: '60%'}}>
                                {this.state.loading ? 
                                <span style={{marginTop: '5px', display: 'inline-block', float: 'right', fontWeight: 'bold'}}>
                                <ClipLoader loading={this.state.loading} size={16} />
                                </span>
                                : 
                                <span style={{marginTop: '5px', display: 'inline-block', float: 'right', fontFamily: 'futura-med'}}>{fullNumber}.{decimals} {this.props.fromCurrency?.id}
                                {Number.parseInt(fullNumber.replaceAll(' ', '')) > maxAmount ? 
                                <span 
                                    onMouseOver={() => {this.setState({showWarning: true})}}
                                    onMouseOut={() => {this.setState({showWarning: false})}}
                                    onClick={() => clickWarning()}
                                    style={{marginLeft: '10px', height: this.state.hover ? '30px' : '20px', marginTop: '2px', position: 'relative'}}
                                >
                                    <AiFillWarning />
                                </span>
                                : null}
                                </span>
                                }
                                </div>
                            </div>
                        </div>
                        <div style={{width: '100%', display: 'flex', alignItems: "center", flexDirection: 'column', marginBottom: '20px'}}>
                            <div style={{height: '60px', margin: '20px 0 auto 0px'}}>
                                <div style={{width: '210px', margin: '0 auto'}} onClick={() => goToForexCashout()}>
                                <button onMouseOver={() => {this.setState({hover: true})}}
                                    onMouseOut={() => {this.setState({hover: false})}}
                                    disabled={ this.state.newToCurrency?.attributes.availableForOnlinePurchase === false}
                                    style={{ cursor: cursor, padding: '5px', color: hoverColor, transition: '0.3s', border: 'black', backgroundColor: buttonColor, borderRadius: '30px', width: '210px', margin: '0 auto', boxShadow: buttonShadow }}>
                                        <p style={{margin: '5px auto', textAlign: 'center', fontFamily: 'futura-bol', letterSpacing: '1px'}}>{resource_buttonText}</p>
                                </button>
                                </div>
                                <div style={{width: '130px', margin: '10px auto 0 auto', height: '20px'}}>
                                    {this.props.market === 'SWE' ? 
                                        <img width="30" src={SwishIcon} alt="swish"></img>
                                    : this.props.market === 'FIN' ?
                                        <img width="30" src={ZimplerIcon} alt="swish"></img>
                                    : null}
                                    <img  style={{marginLeft: '10px', marginRight: '10px', paddingBottom: '3px'}} width="50"  src={VisaIcon} alt="visa"></img>
                                    <img width="30"  src={MastercardIcon} alt="masterCard"></img>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                : null} 
                {this.props.lastUpdated &&
                <div style={{fontSize: '12px', textAlign: 'center', color: '#18161F', fontFamily: 'muli-reg'}}>
                    <span>
                        {resource_updated} {this.props.lastUpdated.date} {this.props.lastUpdated.hour.slice(0, 5)}
                    </span>
                </div>
                }
            </div>
            }
        </div>
    )
}
}

export default CurrencyConverter