import React, { Component } from 'react'
import PropTypes from 'prop-types'

class CurrencyFlag extends Component { 

    render() {
		const spellfix = this.props.primaryFlagCode === 'xc' ? 'd' : ''
		const exceptions = ['xc', 'xp', 'xo', 'xa', 'an']
		const flagCode = exceptions.includes(this.props.primaryFlagCode) ? 'https://www.forex.se/Content/icons/' + this.props.primaryFlagCode + spellfix + '.svg' : 
		'https://www.forex.se/Content/vendors/flag-icon-css-master/flags/1x1/' + this.props.primaryFlagCode + spellfix + '.svg'

		const secondaryFlagCode = exceptions.includes(this.props.secondaryFlagCode) ? 'https://www.forex.se/Content/icons/' + this.props.secondaryFlagCode + spellfix + '.svg' : 
		'https://www.forex.se/Content/vendors/flag-icon-css-master/flags/1x1/' + this.props.secondaryFlagCode + spellfix + '.svg'

        let extraFlag
		if (this.props.secondaryFlagCode && this.props.secondaryFlagCode !== this.props.primaryFlagCode) {
			extraFlag = <div className={`flag-icon-background flag-icon-${this.props.secondaryFlagCode} 
			flag-icon-squared flag-icon-inner`} style={{width: '20px', height: '20px', borderRadius: '50%', border: '1px solid #DDDCDE', position: 'absolute', bottom: '0', right: '0', backgroundSize: 'contain', backgroundImage: (`url(${secondaryFlagCode}`)}}></div>
		}

		if (!this.props.primaryFlagCode) return <div></div>
		
        return (
			<div className={`flag-icon-background flag-icon-${this.props.primaryFlagCode} 
			flag-icon-squared flag-icon-container ${this.props.smallFlag ? 'flag-icon-small' : ''}`}
			style={{marginRight: '15px', minWidth: '44px', minHeight: '44px', borderRadius: '50%', border: '1px solid #DDDCDE', position: 'relative', backgroundImage: (`url(${flagCode}`), backgroundSize: 'contain', backgroundColor: 'grey'}}>
				{extraFlag}
			</div>
        )
    }
}

CurrencyFlag.propTypes = {
	primaryFlagCode: PropTypes.string,
	secondaryFlagCode: PropTypes.string,
	smallFlag: PropTypes.bool
}

export default CurrencyFlag