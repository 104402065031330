import React from 'react'

const DropdownComponent = (ref) => {

    return (
        <div ref={ref} id="dropdown" style={{width: '280px', height: '66px',
        // borderRadius: '10px',
        display: 'block'}}></div>
    )

}

export default DropdownComponent