import React, { Component } from "react";
import CurrencyDescription from "./CurrencyDescription";
import CurrencySearch from "./CurrencySearch";

class CurrencyInput extends Component {
    constructor(props) {
        super(props)
        this.state = { chosenCurrency: {}, newCurrency: false}
    }

    render() {

        const resetValues = (currency) => {
            if (currency !== this.state.chosenCurrency && this.state.newCurrency === false) {
                this.setState({chosenCurrency: currency, newCurrency: true}, () => {
                    setTimeout(() => {
                        this.setState({newCurrency: false})
                    }, 50);
                })
            }
        }

    return (
        <div className="currency-input-container" style={{display: 'flex', marginBottom: '6px', height: '66px', alignItems: 'center'}}>
            <CurrencyDescription
                market={this.props.market}
                popularCurrencies={this.props.popularCurrencies}
                currencies={this.props.currencies}
                countries={this.props.countries}
                fromCurrency={this.props.fromCurrency}
                toCurrency={this.props.toCurrency}
                origin={this.props.origin}
                newToCurrency={this.props.newToCurrency}
                newToCurrencyFunction={this.props.newToCurrencyFunction}
                resetValues={resetValues}
            />
            <div style={{width: '3px', height: '100%', backgroundColor: 'white', display: 'flex'}}>
                <div style={{width: '3px', height: '66%', backgroundColor: '#ffcf00', margin: 'auto'}}></div>
            </div>
            <CurrencySearch 
                placeholder={this.props.placeholder}
                chosenCurrency={this.state.chosenCurrency} 
                changedCurrency={this.props.changedCurrency} 
                newCurrency={this.state.newCurrency} 
                newToCurrency={this.props.newToCurrency} 
                lastFromAmount={this.props.lastFromAmount} 
                lastToAmount={this.props.lastToAmount} 
                getSum={this.props.getSum} 
                goToForexCashout={this.props.goToForexCashout} 
                checkRate={this.props.checkRate} 
                toCurrency={this.props.toCurrency} 
                currencyRates={this.props.currencyRates} 
                rateSum={this.props.rateSum} 
                origin={this.props.origin} 
            />
        </div>
    )
}
}

export default CurrencyInput