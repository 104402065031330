import React, { Component } from "react";
import { useState, useEffect } from "react";
import CurrencyConverter from "./CurrencyConverter";

const CurrencyConverterComponent = () => {
    const [currencies, setCurrencies] = useState([])
    const [countries, setCountries] = useState([])
    const [currencyRates, setCurrencyRates] = useState()
    const [popularCurrencies, setPopularCurrencies] = useState([])
    const [fromCurrency, setFromCurrency] = useState()
    const [toCurrency, setToCurrency] = useState()
    const [lastUpdated, setLastUpdated] = useState()
    const [initialRate, setInitialRate] = useState()

    const [fetchedStatus, setFetchedStatus] = useState(false)

    const [vertMode, setVertMode] = useState(false)
    const [hasKey, setHasKey] = useState(false)

    const currenciesUrl = 'https://api.forex.se/currency/products?lang=sv'
    const countriesUrl = 'https://api.forex.se/currency/countries?lang=sv'
    const currencyRateUrl = 'https://api.forex.se/currency/ExchangeRates'
    const popularCurrenciesUrl = 'https://api.forex.se/currency/markets'
    const priceCategoriesUrl = 'https://api.forex.se/currency/priceCategories'


    useEffect(() => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const partnerKey = urlParams.get('key')
        const vertical = urlParams.get('vertical')
        if (partnerKey === 'egc4ygc3db') { //This key is a temporary solution to not be displayed at unofficial partners and it subject to change.
            setHasKey(true)
        }
        if (vertical === 'true') {
            setVertMode(true)
        }
    }, [])

    useEffect(() => {
        if (fetchedStatus === false) {
        const fetchAllCurrencies = async () => {
            await fetch(currenciesUrl)
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                setCurrencies(response.data)
                setInitCurrencies()
            })
        }

        const fetchAllCountries = async () => {
            await fetch(countriesUrl)
            .then((response) => {
                return response.json()
            })
            .then((countries) => {
                setCountries(countries.data)
            })
        }

        const getPopularCurrencies = async () => {
            await fetch(popularCurrenciesUrl)
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                let swedishMarket = response.data.find(market => market.id === 'SWE')
                setPopularCurrencies(swedishMarket.relationships.popularProducts.data)
            })
        }

        const getAllCurrencyRates = async () => {
            await fetch(currencyRateUrl)
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                let swedishRates = response.data.filter(rate => rate.id.includes('SWE-'))
                setCurrencyRates(swedishRates)
            })
        }

        const getTimestamp = async () => {
            await fetch(priceCategoriesUrl)
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                let timestamp = response.data[0].attributes.timeStamp.split('T')
                setLastUpdated({ date: timestamp[0], hour: timestamp[1].replace('Z', '')})
            })
        }

        getAllCurrencyRates()
        getPopularCurrencies()

        const setInitCurrencies = () => {
            if (currencies.length > 0 && currencyRates) {
                const initialFromCurrency = currencies.find((currency) => currency.id === "SEK")
                const initialToCurrency = currencies.find((currency) => currency.id === "EUR")
                const initialRate = currencyRates.find(rate => rate.attributes.targetCurrencyCode === initialToCurrency?.id)
                setInitialRate(initialRate.attributes.rate)
                setFromCurrency(initialFromCurrency)
                setToCurrency(initialToCurrency)
                setFetchedStatus(true)
            }
        }

        fetchAllCurrencies()
        fetchAllCountries()
        getTimestamp()
        }
    })

    const containerWidth = vertMode ? 'wrapper-vert' : 'wrapper'

    return ( 
        <div className={containerWidth} style={{ objectFit: 'contain', backgroundRepeat: 'no-repeat', backgroundColor: '#ffcf00'}}>
            {hasKey ?
            <>
                <CurrencyConverter vertMode={vertMode} currencies={currencies} countries={countries} popularCurrencies={popularCurrencies} currencyRates={currencyRates} initialRate={initialRate} fromCurrency={fromCurrency} toCurrency={toCurrency} lastUpdated={lastUpdated} />
                <div style={{height: '50px'}}></div>
            </>
        : null}
        </div>
    )
}

export default CurrencyConverterComponent